@import '../theme.scss';

.multiOptionPhoneNumberInput {
  &.focused {
    .input {
      .currentFlag {
        p {
          border-top-color: $color-blue;
          border-bottom-color: $color-blue;
          border-left-color: $color-blue;
          background-color: $color-white;
        }
      }

      .currentPrefix {
        border-top-color: $color-blue;
        border-bottom-color: $color-blue;
        background-color: $color-white;
      }

      > input {
        border-top-color: $color-blue;
        border-bottom-color: $color-blue;
        border-right-color: $color-blue;
        background-color: $color-white;

        &:focus,
        &:focus-within {
          border-top-color: $color-blue;
          border-bottom-color: $color-blue;
          border-right-color: $color-blue;
          background-color: $color-white;
        }
      }
    }
  }

  &.active {
    .input {
      .currentFlag {
        p {
          border-color: $color-blue;
        }
      }
    }

    .options {
      max-height: 200px;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
      padding: 2 * $border-radius 0;
    }
  }

  &.invalid {
    .input {
      .currentFlag {
        p {
          border-top-color: $color-red;
          border-bottom-color: $color-red;
          border-left-color: $color-red;
        }
      }

      .currentPrefix {
        border-top-color: $color-red;
        border-bottom-color: $color-red;
      }

      > input {
        border-top-color: $color-red;
        border-bottom-color: $color-red;
        border-right-color: $color-red;

        &:focus,
        &:focus-within {
          border-top-color: $color-red;
          border-bottom-color: $color-red;
          border-right-color: $color-red;
        }
      }
    }
  }

  .input {
    background-color: $color-light-grey;
    border-radius: $border-radius;
    width: 100%;
    grid-template-columns: auto auto 1fr;
    grid-template-areas: 'flag prefix input';

    .currentFlag {
      grid-area: flag;

      p {
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: 0.02em;
        padding: 1em 0.5em;
        outline: 0;
        border: none;
        border-radius: $border-radius 0 0 $border-radius;
        border: 1px solid rgba(0, 0, 0, 0);
        border-right-color: $color-medium-grey;
        background-color: $color-light-grey;
        transition: all 0.2s ease-in-out;
        position: relative;
        cursor: pointer;
        z-index: 2;
        width: 100%;
        text-align: left;
      }
    }

    .currentPrefix {
      grid-area: prefix;
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: 0.02em;
      padding: 1em 0.5em;
      outline: 0;
      border: none;
      border-top: 1px solid rgba(0, 0, 0, 0);
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      background-color: $color-light-grey;
      transition: all 0.2s ease-in-out;
      position: relative;
      z-index: 2;
    }

    > input {
      grid-area: input;
      border: 1px solid rgba(0, 0, 0, 0);
      border-left: none;
      padding: 0;
      background-color: $color-light-grey;
      padding: 1em 0.5em 1em 0;
      border-radius: 0 $border-radius $border-radius 0;
      z-index: 2;

      &:focus,
      &:focus-within {
        border: 1px solid rgba(0, 0, 0, 0);
        box-shadow: none;
      }
    }
  }

  .options {
    height: auto;
    max-height: 0px;
    overflow: hidden;
    overflow-y: scroll;
    transition: all 0.2s ease-in-out;
    background-color: $color-white;
    border-radius: 0 0 $border-radius $border-radius;
    position: absolute;
    width: 100%;
    top: 43px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: $color-white;
      margin-top: 2 * $border-radius;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-medium-grey;
      border-radius: $border-radius;
    }

    ul {
      .option {
        padding: 0 5px;
        cursor: pointer;

        .flag {
          display: inline-block;
          vertical-align: middle;
          position: relative;
          width: 100%;
          font-size: 14px;
          line-height: 1.25;
          letter-spacing: 0.02em;
          color: $color-black;
          padding: 0.5em 0.5em;
          transition: background 0.2s ease-in-out;
          border-radius: $border-radius;

          img {
            display: inline-block;
            vertical-align: middle;
            translate: 0 -1px;
          }

          .prefix {
            position: absolute;
            right: 0.5em;
          }
        }

        &:hover {
          .flag {
            background-color: $color-light-grey;
          }
        }
      }
    }
  }
}
